<template>
  <div class="redirect-page">
    <cover-loader is-cover />
  </div>
</template>

<script>
import CoverLoader from "../../components/shared/CoverLoader.vue";
import auth from "../../helpers/auth.js";

export default {
  metaInfo: {
    title: "مشروع زادي | إعادة توجيه ",
  },
  components: { CoverLoader },
  computed: {
    refreshTokenLoading() {
      return auth.isLoading;
    },
  },
  methods: {
    onRefreshToken() {
      const token = this.$route.query?.token;
      auth.refreshToken(token);
    },
  },
  created() {
    this.onRefreshToken();
  },
};
</script>

<style scoped>
::v-deep .loader.cover {
  height: calc(100dvh - 0px);
}
</style>
